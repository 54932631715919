import MenuIcon from '@assets/icons/menuIcon';

export const NoNutritionFallback = () => (
  <>
    <div className="display-flex justify-content-center">
      <MenuIcon height="120" width="120" />
    </div>
    <p className="label-l text-align-center spacer-bottom-16">
      Brak podanych wartości odżywczych
    </p>
    <p className="text-m text-align-center">
      Jeśli potrzebujesz dokładnej rozpiski wartości odżywczych dania,
      skontaktuj się z cateringiem.
    </p>
  </>
);
