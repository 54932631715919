import { IngredientT } from '@components/pages/mealExchangeStep/types/ingredient.type';
import { ThermoE } from '@features/badge/types/badge.type';
import { MenuConfigurationMealDetailsT } from '@features/orderForm/stepMenuConfiguration/types/menuConf.type';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UseStateT } from 'typesRoot/generics.type';
import ThermoBadge from '@features/badge/views/thermo/ThermoBadge';
import CustomModal from '@components/common/CustomModal';
import { NoNutritionFallback } from '@features/orderForm/stepMenuConfiguration/views/modals/MenuConfMealDetailsModal/NoNutritionFallback';
import { NoIngredientsFallback } from '@features/orderForm/stepMenuConfiguration/views/modals/MenuConfMealDetailsModal/NoIngredientsFallback';

type Props = {
  carouselName: string;
  details: MenuConfigurationMealDetailsT;
  isOpen: boolean;
  setIsOpen: UseStateT<boolean>;
  visibleIngredients?: boolean;
  visibleNutrition?: boolean;
};

const MenuConfMealDetailsModal = ({
  carouselName,
  details,
  isOpen,
  setIsOpen,
  visibleIngredients,
  visibleNutrition,
}: Props) => {
  if (!isOpen) {
    return <></>;
  }

  const {
    calories = '',
    carbohydrate = '',
    dietaryFiber = '',
    fat = '',
    ingredients = [],
    name: menuMealName = '',
    protein = '',
    salt = '',
    saturatedFattyAcids = '',
    sugar = '',
    thermo,
  } = details;

  const isCorrectThermo = (t: string): t is keyof typeof ThermoE => {
    // @ts-expect-error
    return Object.values(ThermoE).includes(t);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const nutritional = [
    { title: 'Białko', value: protein },
    { title: 'Tłuszcz', value: fat },
    { title: 'Węglowodany', value: carbohydrate },
    { title: 'Błonnik', value: dietaryFiber },
    { title: 'Cukry', value: sugar },
    { title: 'Sól', value: salt },
    {
      title: 'NKT',
      value: saturatedFattyAcids,
    },
  ];

  return (
    <CustomModal
      className="meal-modal"
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName="custom-modal-overlay"
    >
      <FontAwesomeIcon
        className="meal-modal__close font-size-18"
        icon={faTimes}
        onClick={onClose}
      />
      <div className="meal-info">
        {isCorrectThermo(thermo) && <ThermoBadge thermo={thermo} />}
        <span>{carouselName}</span>

        <h4 className="h400 spacer-bottom-24">{menuMealName}</h4>

        {!!calories && (
          <>
            <span>Wartości odżywcze</span>
            {visibleNutrition && (
              <h3 className="h300 spacer-bottom-16">{calories}</h3>
            )}
          </>
        )}

        {visibleNutrition ? (
          <div className="meal-info__nutritional-values">
            {nutritional.map(
              ({ title, value }) =>
                !!value && (
                  <div className="meal-info__item" key={title}>
                    <span>{title}</span> <span>{value}</span>
                  </div>
                )
            )}
          </div>
        ) : (
          <NoNutritionFallback />
        )}

        {ingredients.length && visibleIngredients ? (
          <div className="spacer-top-24">
            <p className="body-m spacer-bottom-4">Składniki</p>
            <p className="body-s">
              {ingredients.map(({ major, name }: IngredientT, index) => (
                <span
                  className={`spacer-right-4 ${major ? 'font-weight-500' : ''}`}
                  key={name}
                >
                  {name}
                  {index + 1 !== ingredients.length && ', '}
                </span>
              ))}
            </p>
          </div>
        ) : (
          <NoIngredientsFallback />
        )}
      </div>
    </CustomModal>
  );
};

export default MenuConfMealDetailsModal;
