import React from 'react';
import NextStepButton from './NextStepButton';
import { parseNumber } from '@utils/parseNumber.util';
import { CalculatePriceItemT } from 'typesRoot/calculatePrice.type';

type Props = {
  days: number;
  prices?: CalculatePriceItemT;
  setValidationStatus: (value: { message: string; value: string }) => void;
};

const SecondStepSummaryBar = ({ days, prices, setValidationStatus }: Props) => {
  const {
    totalDietWithDiscountsAndSideOrdersCost = 0,
    totalSideOrdersCost = 0,
  } = prices || {};

  const toPay = days
    ? totalDietWithDiscountsAndSideOrdersCost - totalSideOrdersCost
    : 0;

  return (
    <div className="steps-page-second__summary-bar">
      <div className="display-flex flex-direction-column justify-content-center">
        <p className="summary-bar__price-title">Suma zamówienia:</p>

        <p className="label-l color-gray-800">{parseNumber(toPay)} zł</p>
      </div>

      <NextStepButton
        isDisabled={!prices}
        prices={prices}
        setValidationStatus={setValidationStatus}
      />
    </div>
  );
};

export default SecondStepSummaryBar;
